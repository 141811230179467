import React from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { authAtom } from "../../atom/auth"
import { useRecoilValue } from "recoil"
export default function SendQuotePopup({ onClose, handleSubmit }) {
  const user = useRecoilValue(authAtom)
  const validationSchema = Yup.object({
    fullName: Yup.string().required("Full Name is required"),
    companyName: Yup.string().required("Company Name is required"),
    jobTitle: Yup.string().required("Job Title is required"),
    email: Yup.string().email("Invalid email address").required("Email Address is required")
  })
  const formik = useFormik({
    initialValues: {
      fullName: user ? `${user.firstName} ${user.lastName}` : "",
      companyName: "",
      jobTitle: "",
      email: user ? user.email : ""
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values)
      onClose()
    }
  })

  return (
    <div className='fixed inset-0 z-[999] flex items-center justify-center bg-black bg-opacity-50 '>
      <div className='bg-white p-8 rounded-lg max-w-md w-full max-h-[90vh] overflow-y-auto'>
        <div className='flex py-2 border-b border-dicom-primary-100/5 justify-between'>
          <span>Request a Quote Email</span>
          <svg
            onClick={onClose}
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-5 cursor-pointer'>
            <path strokeLinecap='round' strokeLinejoin='round' d='M6 18 18 6M6 6l12 12' />
          </svg>
        </div>
        <div className='w-full flex flex-col justify-center items-center gap-y-5 mt-4'>
          <img src='/images/QuotationEmailPopup.svg' alt='' />
          <p className='text-center'>To receive your price quote by email, please provide the following information</p>
        </div>

        <form onSubmit={formik.handleSubmit} className='mt-2 space-y-4 w-[80%] mx-auto'>
          <div className='w-full space-y-2'>
            <span className='block text-sm font-normal'>Full Name</span>
            <input
              type='text'
              name='fullName'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fullName}
              placeholder='Enter your full name'
              className='py-3 block rounded-xl bg-dicom-primary-100/5 placeholder:dicom-text-100 placeholder:text-sm px-4 w-full'
            />
            {formik.touched.fullName && formik.errors.fullName && (
              <span className='text-red-500 text-sm'>{formik.errors.fullName}</span>
            )}
          </div>

          <div className='w-full space-y-2'>
            <span className='block text-sm font-normal'>Company Name</span>
            <input
              type='text'
              name='companyName'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.companyName}
              placeholder='Enter your company name'
              className='py-3 block rounded-xl bg-dicom-primary-100/5 placeholder:dicom-text-100 placeholder:text-sm px-4 w-full'
            />
            {formik.touched.companyName && formik.errors.companyName && (
              <span className='text-red-500 text-sm'>{formik.errors.companyName}</span>
            )}
          </div>

          <div className='w-full space-y-2'>
            <span className='block text-sm font-normal'>Job Title</span>
            <input
              type='text'
              name='jobTitle'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.jobTitle}
              placeholder='Enter your job title'
              className='py-3 block rounded-xl bg-dicom-primary-100/5 placeholder:dicom-text-100 placeholder:text-sm px-4 w-full'
            />
            {formik.touched.jobTitle && formik.errors.jobTitle && (
              <span className='text-red-500 text-sm'>{formik.errors.jobTitle}</span>
            )}
          </div>

          <div className='w-full space-y-2'>
            <span className='block text-sm font-normal'>Email Address</span>
            <input
              type='email'
              name='email'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              placeholder='Enter your email address'
              className='py-3 block rounded-xl bg-dicom-primary-100/5 placeholder:dicom-text-100 placeholder:text-sm px-4 w-full'
            />
            {formik.touched.email && formik.errors.email && (
              <span className='text-red-500 text-sm'>{formik.errors.email}</span>
            )}
          </div>

          <div className='flex justify-between'>
            <button type='button' onClick={onClose} className='mt-4 px-4 py-3 w-full text-dicom-primary-100'>
              Cancel
            </button>
            <button type='submit' className='mt-4 px-4 py-3 w-full text-white rounded-xl bg-dicom-primary-100'>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
